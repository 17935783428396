










import Vue from 'vue';
import PageLayout from '@/components/page/layout.vue';
import TableGenerator, { ITableColumn } from '@/components/tables/generator.vue';
import { TableComponentNames } from '@/components/tables/components/names';
import Component from 'vue-class-component';
import TableNavigation from '@/components/tables/navigation.vue';
import { AreaRecordsModule } from '@/store/areas/records';
import { areaRecordsModule } from '@/store';
import AreaForm from '@/components/areas/areas/form/index.vue';

const schema: ITableColumn[] = [
  { label_i18n: 'id', prop: 'id', width: 80 },
  { label_i18n: 'image', width: '176', component: TableComponentNames.ObjectThumbnail },
  {
    label_i18n: 'description',
    prop: 'description',
    component: TableComponentNames.AreaRecordDescription,
    'min-width': 200
  }
];

@Component({
  components: { AreaForm, TableNavigation, PageLayout, TableGenerator }
})
export default class AreaRecords extends Vue {
  get state(): AreaRecordsModule {
    return areaRecordsModule;
  }

  get schema(): ITableColumn[] {
    return schema;
  }

  mounted() {
    this.state.setAutoUpdate(true);
  }

  beforeDestroy() {
    this.state.setAutoUpdate(false);
  }

  rowClickHandler({ item }) {}
}
